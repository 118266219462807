<template>
  <div
    v-infinite-scroll="loadMore"
    infinite-scroll-disabled="busy"
    infinite-scroll-distance="10"
  >
    <r-page>
      <template #page-header>
        <h2 class="page-title">Quote Setting</h2>
      </template>
      <template #action-bar>
        <div class="level">
          <div class="level-left">
            <b-button
              tag="button"
              class="is-command"
              icon-left="plus"
              @click="openModalCreate()"
            >
              New
            </b-button>
          </div>
        </div>
      </template>
      <template #page-content>
        <div id="divisions-table">
          <b-table
            :data="quotesList"
            :loading="isLoading"
            ref:table
            hoverable
            backend-sorting
            :default-sort-direction="defaultSortOrder"
            :default-sort="[sortField, sortOrder]"
            class="employment-directory-table table-log-activity-personal general-table"
            @sort="onSort"
            height="760px"
            :sticky-header="stickyHeaders"
          >
            <template>
              <b-table-column label="No" v-slot="props" width="5%">
                <span class="is-capitalize">
                  {{ props.index + 1 }}
                </span>
              </b-table-column>
              <b-table-column
                field="quote"
                label="Quote"
                v-slot="props"
                sortable
                width="67%"
              >
                <span class="is-capitalize">{{ props.row.quote }}</span>
              </b-table-column>
              <b-table-column label="Action" v-slot="props" width="8%">
                <b-icon
                  type="is-primary"
                  icon="pencil"
                  custom-size="mdi-18px"
                  class="employment-edit-icon"
                  @click.native="openModalCreate(props.row)"
                ></b-icon>
                <b-icon
                  type="is-primary"
                  icon="delete"
                  custom-size="mdi-18px"
                  class="click"
                  @click.native="deleteQuotes(props.row.id)"
                ></b-icon>
              </b-table-column>
            </template>
            <template #empty>
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <p>No data found</p>
                </div>
              </section>
            </template>
          </b-table>
        </div>

        <QuoteSettingModal
          :show="isModalCreateOpen"
          :loadingSubmit="isSubmitting"
          :formData="form"
          :isEditing="isEditing"
          @hide="closeModalCreate"
          @submit="submit"
        />
      </template>
    </r-page>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { showToast } from '@/services/util'

// component
import QuoteSettingModal from './QuoteSetting/QuoteSettingModal.vue'

export default {
  data() {
    return {
      isModalCreateOpen: false,
      isEditing: false,
      isLoading: false,
      stickyHeaders: true,
      form: {
        id: null,
        quote: null,
      },
      isSubmitting: false,
      isLoadMore: false,
      perPage: 20,
      page: 0,
      lastPage: 0,
      defaultSortOrder: 'desc',
      sortField: 'id',
      sortOrder: 'desc',
    }
  },
  computed: {
    ...mapGetters({
      quotesList: 'quotes/getQuotesList',
    }),
  },
  async mounted() {
    this.setQuotesList([])
    await this.loadQuotesList()
  },
  methods: {
    ...mapActions({
      actionLoadQuotesList: 'quotes/fetchQuotesList',
      saveQuote: 'quotes/saveQuote',
      updateQuote: 'quotes/editQuote',
      deleteQuote: 'quotes/deleteQuote',
    }),
    ...mapMutations({
      setQuotesList: 'quotes/setQuotesList',
    }),

    /**
     * Open create/ edit quote modal
     * @param {Object} row - selected quote from table
     */
    openModalCreate(row) {
      if (row) {
        this.isEditing = true
        this.form = {
          id: row.id,
          quote: row.quote,
        }
      }
      this.isModalCreateOpen = true
    },

    /**
     * Close create/ edit quote modal
     */
    closeModalCreate() {
      this.resetForm()
      this.isModalCreateOpen = false
      this.isEditing = false
    },

    /**
     * Reset Form when modal is closed
     */
    resetForm() {
      this.form = {
        id: null,
        quote: null,
      }
    },

    /**
     * Load Quotes data
     */
    async loadQuotesList() {
      this.isLoading = true
      try {
        const response = await this.actionLoadQuotesList({
          perPage: this.perPage,
          page: ++this.page,
          sortField: this.sortField,
          sortOrder: this.sortOrder,
        })
        this.quotes = response.data.data
        this.total = response.data.total
        this.lastPage = response.data.lastPage
      } catch (err) {
        console.log(err)
      }
      this.isLoading = false
    },

    /**
     * Sort table
     * @param {String} field - name of field
     * @param {String} order - asc or desc
     */
    onSort(field, order) {
      this.sortField = field
      this.sortOrder = order
      this.resetState()
    },

    /**
     * Reset state of table
     * @param {String} searchInput - keyword search (optional)
     */
    async resetState() {
      this.page = 0
      this.lastPage = 0
      this.setQuotesList([])
      await this.loadQuotesList()
    },

    /**
     * load more table data
     */
    async loadMore() {
      if (this.page < this.lastPage) {
        this.isLoadMore = true
        await this.actionLoadQuotesList()
        this.isLoadMore = false
      }
    },

    /**
     * Delete selected quotes
     * @param {integer} id - selected quotes ID
     */
    async deleteQuotes(id) {
      let res = await this.$swal({
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        customClass: {
          confirmButton: 'button is-primary',
          cancelButton: 'button',
        },
        text: `Are you sure? You can't undo this action`,
      })
      try {
        if (res && res.isConfirmed) {
          await this.deleteQuote(id)
          this.$swal({
            icon: 'success',
            titleText: 'Data is successfully deleted!',
            confirmButtonText: 'Done',
            customClass: {
              confirmButton: 'button is-success',
            },
          })
          this.resetForm()
        }
      } catch (e) {
        this.$swal({
          icon: 'error',
          titleText: 'Failed. Please try again!',
          text: e,
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'button is-primary',
          },
        })
      }
    },

    /**
     * Submit new/ edited quotes
     */
    async submit() {
      this.isSubmitting = true
      let form = new FormData()
      form.append('quote', this.form.quote)
      if (!this.isEditing) {
        try {
          await this.saveQuote(form)
          showToast('Quote Added!', 'is-success', 'is-top')
        } catch (e) {
          showToast(e.message, 'is-danger', 'is-top')
        }
      } else {
        try {
          const data = { id: this.form.id, form: this.form }
          await this.updateQuote(data)
          showToast('Quote Edited!', 'is-success', 'is-top')
        } catch (e) {
          showToast(e.message, 'is-danger', 'is-top')
        }
      }
      this.isSubmitting = false
      this.closeModalCreate()
    },
  },
  components: { QuoteSettingModal },
}
</script>
